import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Layout from '../components/layout'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  img {
    width: auto;
    max-width: 100%;
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

const Body = styled.div`
  a {
    color: blue;
  }
  text-align: justify;
`

class SimfCatalogueTemplate extends React.Component {
  render() {
    const post = this?.props?.data?.contentfulSimfCatalogue

    return (
      <YMaps>
        <Layout location={this.props.location}>
          <Div>
            <Helmet title={post?.title} />
            <div className="wrapper">
              <h1 className="section-headline">{post.title}</h1>
              <p
                style={{
                  display: 'block',
                }}
              >
                {post.address}
              </p>
              <Map
                width="100%"
                height="540px"
                defaultState={{
                  center: [post.geo.lat, post.geo.lon],
                  zoom: 16,
                }}
              >
                <Placemark geometry={[post.geo.lat, post.geo.lon]} />
              </Map>
              <Body
                dangerouslySetInnerHTML={{
                  __html: post?.body?.childMarkdownRemark?.html,
                }}
              />
            </div>
          </Div>
        </Layout>
      </YMaps>
    )
  }
}

export default SimfCatalogueTemplate

export const pageQuery = graphql`
  query SimfCatalogueQuery($slug: String!) {
    contentfulSimfCatalogue(slug: { eq: $slug }) {
      geo {
        lat
        lon
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      address
      slug
      title
    }
  }
`
